import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { NavLink } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import TeamItem from "../components/team-item";

export default function Team() {
  const [team, setTeam] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "team"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get team
        axios
          .get(
            CONTENT_ROUTES.TEAM("?order_by=menu_order&order=asc&per_page=100")
          )
          .then((res) => {
            setTeam(res.data);

            //Get team media
            let media = [];

            res.data?.map((member, i) => {
              if (member?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(member?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setTeamMedia((prevState) => [...prevState, media[i]]);
                  });
              return member?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8"></div>

        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                {page?.title?.rendered}
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-16 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 pl-0 list-none">
              {team.map((member, i) => (
                <Fragment key={i}>
                  <TeamItem
                    member={member}
                    memberMedia={teamMedia?.find(
                      (media) => media.id === member?.featured_media
                    )}
                  />
                </Fragment>
              ))}
            </ul>
          </div>
        </section>

        <section className="relative isolate overflow-hidden">
          <img
            src={
              pageMedia?.find(
                (media) => media.id === page?.acf?.team_page_lower_banner_image
              )?.source_url
            }
            alt={page.title?.rendered}
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="h-full w-full bg-gray-900/40 px-8 py-48">
            <div className="container mx-auto text-center max-w-2xl">
              <h2 className="md:text-4xl text-3xl text-white mb-10">
                <span
                  dangerouslySetInnerHTML={{
                    __html: page?.acf?.team_page_lower_banner_title,
                  }}
                />
              </h2>

              <NavLink
                to={page?.acf?.team_page_lower_banner_cta.url}
                className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block"
              >
                {page?.acf?.team_page_lower_banner_cta.title}{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
              </NavLink>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
