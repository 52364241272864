import { useState, useEffect } from "react";
import axios from "axios";
import { useMatch } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Page() {
  const slug = useMatch("/:slug/*");
  const pageSlugs = slug.pathname?.split("/")?.filter((str) => str !== "");
  const pageSlug = pageSlugs[pageSlugs.length - 1];
  const [page, setPage] = useState({});

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", pageSlug + `&_embed`))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
      })
      .catch((err) => {})
      .finally(() => {});
  }, [pageSlug]);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>
        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                {page?.title?.rendered}
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
              <div className="max-w-4xl">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
