import { useState, useEffect } from "react";
import { useFormValidation } from "../hooks/form-validation";
import { useForms } from "../hooks/forms";
import Alert from "./alert";

export default function FormContact() {
  let { validFormDetails } = useFormValidation();
  let { submitContactForm } = useForms();
  const [invalidFormDetails, setInvalidFormDetails] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [formError, setFormError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formFields = [
    { name: "first_name", display: "your First name" },
    { name: "last_name", display: "your Last name" },
    { name: "email", display: "your Email address" },
    { name: "message", display: "Message" },
  ];

  useEffect(() => {
    /* Defaults */
    setFormDetails((prevState) => ({
      ...prevState,
      [`first_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`last_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`email`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`organisation`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`message`]: "",
    }));

    return () => {
      setInvalidFormDetails({});
      setFormDetails({});
      setFormError("");
      setFormSubmitted(false);
    };
  }, []);

  return (
    <>
      {!formSubmitted ? (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitContactForm(
              formFields,
              formDetails,
              setInvalidFormDetails,
              setFormError,
              setFormSubmitted
            );
          }}
          method="POST"
          className="mx-full"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first_name"
                className="block font-semibold leading-6 text-gray-900"
              >
                First name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="first_name"
                  className={`block w-full border-0 px-3.5 py-2 text-gray-900 ${
                    invalidFormDetails.first_name ? `bg-red-50` : `bg-gray-100`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`first_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.first_name ? formDetails.first_name : ""}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block font-semibold leading-6 text-gray-900"
              >
                Last name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="last_name"
                  className={`block w-full border-0 px-3.5 py-2 text-gray-900 ${
                    invalidFormDetails.last_name ? `bg-red-50` : `bg-gray-100`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`last_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.last_name ? formDetails.last_name : ""}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="organisation"
                className="block font-semibold leading-6 text-gray-900"
              >
                Organisation
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="organisation"
                  id="organisation"
                  autoComplete="organisation"
                  className={`block w-full border-0 px-3.5 py-2 text-gray-900 ${
                    invalidFormDetails.organisation
                      ? `bg-red-50`
                      : `bg-gray-100`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`organisation`]: event.target.value,
                    }));
                  }}
                  value={
                    formDetails.organisation ? formDetails.organisation : ""
                  }
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block font-semibold leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className={`block w-full border-0 px-3.5 py-2 text-gray-900 ${
                    invalidFormDetails.email ? `bg-red-50` : `bg-gray-100`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`email`]: event.target.value,
                    }));
                  }}
                  value={formDetails.email ? formDetails.email : ""}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block font-semibold leading-6 text-gray-900"
              >
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  autoComplete="message"
                  className={`block w-full border-0 px-3.5 py-2 text-gray-900 ${
                    invalidFormDetails.message ? `bg-red-50` : `bg-gray-100`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`message`]: event.target.value,
                    }));
                  }}
                  value={formDetails.message ? formDetails.message : ""}
                ></textarea>
              </div>
            </div>
            <div className="flex gap-x-6 sm:col-span-2 items-start">
              <p className="text-sm">
                This form is protected by reCAPTCHA. Google's{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en-GB"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                {` `}
                and
                {` `}
                <a
                  href="https://policies.google.com/terms?hl=en-GB"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
                {` `}
                apply.
              </p>

              <button
                type="submit"
                className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block md:w-28 w-52"
              >
                Send{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 "></i>
              </button>
            </div>
            <div className="sm:col-span-2">
              {formError.length !== 0 && (
                <div className="p-4 bg-red-50 text-red-600">{formError}</div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <>
          <Alert
            type={`success`}
            message={`<b>Successfully submitted.</b> We will be in touch with you shortly.`}
          />
        </>
      )}
    </>
  );
}
