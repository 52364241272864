import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import Header from "../components/header";
import Footer from "../components/footer";
import { AppLoader } from "../components/app-loader";

export default function NewsSingle() {
  const [article, setArticle] = useState({});
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  const singleArticle = useMatch(WEB_ROUTES.NEWS + "/:news");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    setLoading(true);

    //Get service
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          singleArticle?.params?.news + "&_embed&order_by=menu_order&order=asc"
        )
      )
      .then((res) => {
        let newsResponse = res.data?.length ? res.data[0] : {};
        console.log(newsResponse);
        setArticle(newsResponse);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [singleArticle]);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8md:px-0 px-8"></div>

        {Object.keys(article).length ? (
          <>
            <section className="bg-white pt-16 md:pb-16 pb-8">
              <div className="container mx-auto">
                <div className="mx-auto md:flex items-center">
                  <div className="md:w-5/12 w-full">
                    <div className="mb-5 bg-red-600 p-2 text-xs float-left md:mx-0 mx-8">
                      <span className="text-white">
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              new Date(article.date).getDate() +
                              " " +
                              monthNames[new Date(article.date).getMonth()] +
                              "<br/>" +
                              new Date(article.date).getFullYear(),
                          }}
                        />
                      </span>
                    </div>
                    <div className="clear-both"></div>
                    <div className="text-base leading-7 text-gray-700 w-full md:px-0 px-8">
                      <h1 className="pb-5 mr-5 md:text-5xl text-3xl font-semibold">
                        {article?.title?.rendered}
                      </h1>
                      <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                    </div>
                  </div>

                  <div className="relative md:w-9/12 w-full mt-10 md:mt-0">
                    <img
                      className=" w-full object-cover"
                      src={
                        article?._embedded["wp:featuredmedia"][0]?.source_url
                      }
                      alt={article?.title?.rendered}
                    />

                    <div
                      className="z-50 absolute bottom-0 left-0 bg-black/30 py-2 px-4 text-white text-sm"
                      dangerouslySetInnerHTML={{
                        __html:
                          article?._embedded["wp:featuredmedia"][0]?.caption
                            ?.rendered,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <div className="bg-white pb-16">
              <div className="container mx-auto md:px-0 px-8">
                <div className="md:max-w-5xl w-full">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article?.content?.rendered?.replace(
                        /<p.*?>/g,
                        "<p class='mb-5'>"
                      ),
                    }}
                  />
                </div>

                <NavLink
                  to={WEB_ROUTES.NEWS}
                  className="float-right bg-red-600 px-5 py-2 text-white hover:bg-gray-950 mt-10 inline-block"
                >
                  More news{" "}
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </NavLink>
                <div className="clear-both"></div>
              </div>
            </div>
          </>
        ) : (
          <AppLoader />
        )}
      </main>
      <Footer />
    </>
  );
}
