import { useContext } from "react";
import { OpenCloseContactContext } from "../contexts/contact";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function Footer() {
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  return (
    <footer
      className="bg-gray-950 bg-no-repeat bg-right-top md:bg-[length:50%_auto] bg-[length:100%_auto]"
      aria-labelledby="footer-heading"
      style={{ backgroundImage: `url("/images/footer-bg-pattern.png")` }}
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto container sm:pb-8 pb-16 md:pt-28 pt-20 md:px-0 px-8">
        <NavLink to={WEB_ROUTES.HOME}>
          <img className="mb-5 h-10" src="/images/icon.png" alt="Sakhiwo" />
        </NavLink>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="md:mt-16 mt-8 grid grid-cols-1 md:grid-cols-2 gap-8 md:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="font-semibold text-white">Who we are</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <NavLink
                      to={WEB_ROUTES.ABOUT}
                      className="text-neutral-400 hover:text-white"
                    >
                      About us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.TEAM}
                      className="text-neutral-400 hover:text-white"
                    >
                      Our team
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="font-semibold text-white">What are great at</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_TURNKEY}
                      className="text-neutral-400 hover:text-white"
                    >
                      Turnkey Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_HEALTH_FACILITY}
                      className="text-neutral-400 hover:text-white"
                    >
                      Health Facility Planning
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_HEALTH_TECH}
                      className="text-neutral-400 hover:text-white"
                    >
                      Health Technology Planning and Management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_COMMISSIONING}
                      className="text-neutral-400 hover:text-white"
                    >
                      Commissioning
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_PROJECT_MANAGEMENT}
                      className="text-neutral-400 hover:text-white"
                    >
                      Programme and Project Management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.SERVICES_FACILITIES_MANAGEMENT}
                      className="text-neutral-400 hover:text-white"
                    >
                      Facilities Maintenance Management
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="font-semibold text-white">Our projects</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <NavLink
                      to={WEB_ROUTES.PROJECTS}
                      className="text-neutral-400 hover:text-white"
                    >
                      All projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.PROJECTS_TYPE + "/completed"}
                      className="text-neutral-400 hover:text-white"
                    >
                      Completed projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.PROJECTS_TYPE + "/ongoing"}
                      className="text-neutral-400 hover:text-white"
                    >
                      Ongoing projects
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="font-semibold text-white">Get in touch</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <NavLink
                      to={WEB_ROUTES.CAREERS}
                      className="text-neutral-400 hover:text-white"
                    >
                      Work with us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={WEB_ROUTES.NEWSLETTER}
                      className="text-neutral-400 hover:text-white"
                    >
                      Subscribe to our mailing list
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`#.`}
                      onClick={() => setOpenCloseContact(true)}
                      className="text-neutral-400 hover:text-white"
                    >
                      Contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="group md:mt-0 mt-8">
              <a
                href="https://www.linkedin.com/company/sakhiwo-health-solutions"
                target="_blank"
                rel="noreferrer"
                className="text-neutral-400 group-hover:text-red-600 md:float-end"
              >
                <h3 className="font-semibold text-white float-left mr-2">
                  Follow us on LinkedIn
                </h3>

                <svg
                  className="h-6 w-6 float-left"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                </svg>

                <div className="clear-both"></div>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-white/10 pt-8 md:space-x-4 space-x-0 md:space-y-0 space-y-4">
          <NavLink
            to={WEB_ROUTES.POPI}
            className=" text-neutral-400 md:float-left hover:text-white md:inline block"
          >
            POPI
          </NavLink>
          <NavLink
            to={WEB_ROUTES.COOKIE_POLICY}
            className=" text-neutral-400 md:float-left hover:text-white md:inline block"
          >
            Cookie policy
          </NavLink>
          <NavLink
            to={WEB_ROUTES.PRIVACY_POLICY}
            className=" text-neutral-400 md:float-left hover:text-white md:inline block"
          >
            Privacy policy
          </NavLink>
          <NavLink
            to={WEB_ROUTES.TERMS}
            className=" text-neutral-400 md:float-left hover:text-white md:inline block"
          >
            Terms of use
          </NavLink>
          <NavLink
            to={WEB_ROUTES.COPYRIGHT_STATEMENT}
            className="text-neutral-400 md:float-right hover:text-white float-left mb-0 md:mt-0 mt-4"
          >
            &copy; Sakhiwo - {new Date().getFullYear()}. All rights reserved.
          </NavLink>

          <div className="clear-both"></div>
        </div>
      </div>
    </footer>
  );
}
