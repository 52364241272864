import { useState, useContext, useEffect, useCallback } from "react";
import { LoaderContext } from "../contexts/loader";
import { apiClient } from "../config/supabaseClient";
import { useFormValidation } from "./form-validation";

export const useForms = () => {
  let { setLoading } = useContext(LoaderContext);
  let { validFormDetails } = useFormValidation();

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  /* useEffect(() => {
    // Load the reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=6LdwfS0qAAAAADL-Y35c0EINFiEnUyVM8Rk0D0dU`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Execute reCAPTCHA and get the token
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LdwfS0qAAAAADL-Y35c0EINFiEnUyVM8Rk0D0dU", {
            action: "submit",
          })
          .then((token) => {
            setRecaptchaToken(token);
          });
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []); */

  const onVerify = useCallback((token) => {
    setRecaptchaToken(token);
  });

  function addHours(date, hours) {
    const hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
  }

  function submitContactForm(
    formFields,
    formDetails,
    setInvalidFormDetails,
    setFormError,
    setFormSubmitted
  ) {
    if (
      validFormDetails(
        formFields,
        formDetails,
        setInvalidFormDetails,
        setFormError
      )
    ) {
      if (!recaptchaToken) {
        console.error("No reCAPTCHA token available.");
        setFormError("Inavlid CAPTCHA results.");
        return;
      }

      setLoading(true);

      try {
        /* axios
          .get("/api/verify-recaptcha", {
            recaptchaToken,
            formDetails,
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            setFormError(error.toString());
          })
          .finally(() => {
            setLoading(false);
          }); */
        delete apiClient.defaults.headers["Prefer"];
        apiClient
          .post("/rest/v1/contacts", {
            created_at: addHours(new Date(), 2),
            ...formDetails,
          })
          .then((res) => {
            setFormSubmitted(true);
            setRefreshReCaptcha((r) => !r);
          })
          .catch((err) => {
            setFormError(err.toString().replace("AxiosError:", ""));
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(
          "Error submitting data:",
          error.response ? error.response.data : error.message
        );
        setFormError("Error submitting data.");
      }
    }
  }

  function submitNewsletterForm(
    formFields,
    formDetails,
    setInvalidFormDetails,
    setFormError,
    setFormSubmitted
  ) {
    if (
      validFormDetails(
        formFields,
        formDetails,
        setInvalidFormDetails,
        setFormError
      )
    ) {
      if (!recaptchaToken) {
        console.error("No reCAPTCHA token available.");
        setFormError("Inavlid CAPTCHA results.");
        return;
      }

      setLoading(true);

      try {
        /* axios
          .get("/api/verify-recaptcha", {
            recaptchaToken,
            formDetails,
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            setFormError(error.toString());
          })
          .finally(() => {
            setLoading(false);
          }); */

        //apiClient.defaults.headers["Prefer"] = "resolution=merge-duplicates";

        apiClient
          .post("/rest/v1/newsletter", {
            created_at: addHours(new Date(), 2),
            ...formDetails,
          })
          .then((res) => {
            setFormSubmitted(true);
            setRefreshReCaptcha((r) => !r);
          })
          .catch((err) => {
            setFormError(err.toString().replace("AxiosError:", ""));
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(
          "Error submitting data:",
          error.response ? error.response.data : error.message
        );
        setFormError("Error submitting data.");
      }
    }
  }

  return { submitContactForm, submitNewsletterForm, addHours };
};
