import { useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { AppLoader } from "../components/app-loader";

export default function About() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "about"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>
        <section className="bg-white md:py-16 pt-0 pb-10">
          <div className="container mx-auto">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 md:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-4">
                <div className="relative">
                  <img
                    className="h-full w-full object-cover"
                    src={
                      pageMedia?.find(
                        (media) => media?.id === page?.featured_media
                      )?.source_url
                    }
                    alt={page?.title?.rendered}
                  />
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 md:px-0 px-8">
                  <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                    {page?.title?.rendered}
                  </h1>
                  <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                  <div className="max-w-xl">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />

                    <a
                      href="#mission-vision"
                      className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block transition-all duration-500 ease-out w-[150px] hover:w-[165px]"
                    >
                      Learn more
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-1 ml-1.5"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute w-1/2 h-full bg-gray-950 right-0 top-0 -z-10"></div>
          <div className="container mx-auto bg-gray-950 md:px-16 px-8 md:py-20 py-10">
            <div
              className="text-base md:text-xl text-white pb-5"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.about_sub_section,
              }}
            />

            <div className="mx-auto grid max-w-2xl grid-cols-1 md:gap-28 gap-0 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {page?.acf?.about_sub_section_fields?.map((field, i) => (
                <div
                  key={i}
                  className="flex gap-x-4 md:py-10 py-4 items-center"
                >
                  <div
                    className={`md:h-20 ${
                      i === 1 ? "md:w-auto w-10" : "h-10"
                    } `}
                    dangerouslySetInnerHTML={{
                      __html: field?.about_sub_section_field_icon,
                    }}
                  />

                  <div className="text-base leading-7">
                    <h3 className="text-white !m-0">
                      {field?.about_sub_section_field_title}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {Object.keys(page).length ? (
          <section id="mission-vision" className="bg-white md:py-16 py-10">
            <div className="mx-auto container md:px-0 px-8">
              <div className="mx-auto max-w-2xl lg:text-center md:mb-5 mb-0">
                <h2 className="md:py-5 md:text-4xl text-3xl font-semibold">
                  {page?.acf?.more_about_title}
                </h2>
              </div>
              <div className="mx-auto">
                <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                  {page?.acf?.more_about_fields?.map((field, i) => (
                    <div
                      key={i}
                      className={`relative md:pl-20 pl-10 ${
                        i % 3 === 0 ? "bg-white" : "md:bg-gray-100"
                      } md:py-10 pt-10 pr-10`}
                    >
                      <dt className="text-xl font-semibold text-gray-900">
                        <div className="absolute left-0 top-0 flex md:px-5 px-0 py-10 mdpl-10 items-center justify-center text-red-600">
                          0{i + 1}
                        </div>
                        {field.more_about_field_title}
                      </dt>
                      <dd className="mt-2 text-base text-gray-600">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: field.more_about_field_description,
                          }}
                        />
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}

        <section className="relative isolate overflow-hidden">
          <img
            src={
              pageMedia?.find(
                (media) => media.id === page?.acf?.about_page_lower_banner_image
              )?.source_url
            }
            alt={page.title?.rendered}
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="h-full w-full bg-gray-900/40 px-8 py-48">
            <div className="container mx-auto text-center max-w-2xl">
              <h2 className="md:text-4xl text-3xl text-white mb-10">
                <span
                  dangerouslySetInnerHTML={{
                    __html: page?.acf?.about_page_lower_banner_title,
                  }}
                />
              </h2>

              <NavLink
                to={page?.acf?.about_page_lower_banner_cta?.url}
                className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block"
              >
                {page?.acf?.about_page_lower_banner_cta?.title}{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
              </NavLink>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
