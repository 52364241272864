import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import NewsItem from "../components/news-item";

export default function News() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState({});

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          "&_embed&order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>

        <section className="bg-white md:pt-16 pt-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                Latest news and insights
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
            </div>
          </div>
        </section>

        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts?.map((article, i) => (
                <Fragment key={i}>
                  <NewsItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
