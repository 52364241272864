import { useRef, useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import FormContact from "./form-contact";

export default function Header() {
  const [activeTab, setActiveTab] = useState(0);
  let { openCloseContact, setOpenCloseContact } = useContext(
    OpenCloseContactContext
  );
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (openCloseContact !== undefined && openCloseContact !== null)
      setOpen(openCloseContact);
  }, [openCloseContact]);

  useEffect(() => {
    if (openCloseContact !== undefined && openCloseContact !== null && !open)
      setOpenCloseContact(open);
  }, [open]);

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "contact"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <header className="site-header is--white md:py-3 py-5" id="sticky-menu">
      <div className="container mx-auto md:px-0 px-8">
        <div className="flex items-center justify-between gap-x-8">
          <a href={WEB_ROUTES.HOME} className="inline-block w-52 h-auto">
            <img
              src="/images/sakhiwo-logo-full-colour.png"
              alt="Sakhiwo Logo"
            />
          </a>

          <div className="menu-block-wrapper">
            <div className="menu-overlay"></div>
            <nav className="menu-block" id="append-menu-header">
              <div className="mobile-menu-head">
                <div className="go-back">
                  <img
                    className="dropdown-icon"
                    src="/images/icon-black-long-arrow-right.svg"
                    alt="cheveron-right"
                    width="16"
                    height="16"
                  />
                </div>
                <div className="current-menu-title"></div>
                <div className="mobile-menu-close">&times;</div>
              </div>
              <ul className="site-menu-main items-center">
                <li className="nav-item group">
                  <a href={WEB_ROUTES.HOME} className="nav-link-item group">
                    Home
                    <div
                      className={`absolute -bottom-2 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                    {WEB_ROUTES.HOME === location.pathname && (
                      <div
                        className={`absolute -bottom-2 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                </li>
                <li className="nav-item nav-item-has-children group">
                  <a
                    href={WEB_ROUTES.ABOUT}
                    className="nav-link-item drop-trigger"
                  >
                    About
                    <img
                      className="dropdown-icon"
                      src="/images/icon-black-cheveron-right.svg"
                      alt="cheveron-right"
                      width="16"
                      height="16"
                    />
                    <div
                      className={`absolute bottom-3 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                    {(WEB_ROUTES.ABOUT === location.pathname ||
                      WEB_ROUTES.TEAM === location.pathname) && (
                      <div
                        className={`absolute bottom-3 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                  <ul className="sub-menu" id="submenu-1">
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.ABOUT}>About us</a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.TEAM}>Leadership team</a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item nav-item-has-children group">
                  <a
                    href={WEB_ROUTES.SERVICES}
                    className="nav-link-item drop-trigger"
                  >
                    Services
                    <img
                      className="dropdown-icon ml-1"
                      src="/images/icon-black-cheveron-right.svg"
                      alt="cheveron-right"
                      width="16"
                      height="16"
                    />
                    <div
                      className={`absolute bottom-3 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                    {(WEB_ROUTES.SERVICES === location.pathname ||
                      location.pathname.indexOf(WEB_ROUTES.SERVICES) !==
                        -1) && (
                      <div
                        className={`absolute bottom-3 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                  <ul className="sub-menu" id="submenu-2">
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_TURNKEY}>Turnkey Services</a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_HEALTH_FACILITY}>
                        Health Facility Planning
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_HEALTH_TECH}>
                        Health Technology Planning and Management
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_COMMISSIONING}>
                        Commissioning
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_PROJECT_MANAGEMENT}>
                        Programme and Project Management
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href={WEB_ROUTES.SERVICES_FACILITIES_MANAGEMENT}>
                        Facilities Maintenance Management
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item group">
                  <a href={WEB_ROUTES.PROJECTS} className="nav-link-item">
                    Projects
                    <div
                      className={`absolute -bottom-2 left-0 border-b-[3px] border-black transition-all duration-500  
                ease-out w-0 group-hover:w-full`}
                    ></div>
                    {(WEB_ROUTES.PROJECTS === location.pathname ||
                      location.pathname.indexOf(WEB_ROUTES.PROJECTS) !==
                        -1) && (
                      <div
                        className={`absolute -bottom-2 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                </li>
                <li className="nav-item group">
                  <a href={WEB_ROUTES.NEWS} className="nav-link-item">
                    News
                    <div
                      className={`absolute -bottom-2 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                    {(WEB_ROUTES.NEWS === location.pathname ||
                      location.pathname.indexOf(WEB_ROUTES.NEWS) !== -1) && (
                      <div
                        className={`absolute -bottom-2 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                </li>
                <li className="nav-item group">
                  <a href={WEB_ROUTES.CAREERS} className="nav-link-item">
                    Careers
                    <div
                      className={`absolute -bottom-2 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                    {WEB_ROUTES.CAREERS === location.pathname && (
                      <div
                        className={`absolute -bottom-2 left-0 border-b-[3px] border-red-600 w-full`}
                      ></div>
                    )}
                  </a>
                </li>
                <li className="nav-item group">
                  <NavLink
                    to={`#`}
                    onClick={() => setOpen(true)}
                    className="nav-link-item"
                  >
                    Contact
                    <div
                      className={`absolute -bottom-2 left-0 border-b-[3px] border-black transition-all duration-500 ease-out w-0 group-hover:w-full`}
                    ></div>
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/sakhiwo-health-solutions"
                    target="_blank"
                    rel="noreferrer"
                    className="md:text-neutral-400 group-hover:text-red-600 md:float-end"
                  >
                    <svg
                      className="h-6 w-6 float-left"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          {!open && (
            <div className="flex items-center gap-6 block lg:hidden">
              <div className="">
                <label
                  className="relative z-20 cursor-pointer px-3 py-6"
                  htmlFor="mobile-menu"
                >
                  <input
                    className="peer hidden"
                    type="checkbox"
                    id="mobile-menu"
                  />
                  <div className="relative z-50 block h-[1px] w-7 bg-black bg-transparent content-[''] before:absolute before:top-[-0.35rem] before:z-50 before:block before:h-full before:w-full before:bg-black before:transition-all before:duration-200 before:ease-out before:content-[''] after:absolute after:right-0 after:bottom-[-0.35rem] after:block after:h-full after:w-full after:bg-black after:transition-all after:duration-200 after:ease-out after:content-[''] peer-checked:bg-transparent before:peer-checked:top-0 before:peer-checked:w-full before:peer-checked:rotate-45 before:peer-checked:transform after:peer-checked:bottom-0 after:peer-checked:w-full after:peer-checked:-rotate-45 after:peer-checked:transform"></div>
                  <div className="fixed inset-0 z-40 hidden h-full w-full bg-black/50 backdrop-blur-sm peer-checked:block">
                    &nbsp;
                  </div>
                  <div className="fixed top-0 right-0 z-40 h-full w-full translate-x-full overflow-y-auto overscroll-y-none transition duration-500 peer-checked:translate-x-0">
                    <div className="float-right min-h-full w-[85%] bg-white px-10 py-10 shadow-2xl">
                      <a href={WEB_ROUTES.HOME} className="float-left">
                        <img
                          className="mb-5 h-10"
                          src="/images/icon.png"
                          alt="Sakhiwo"
                        />
                      </a>
                      <div className="clear-both"></div>
                      <menu className="mt-8 mb-8 flex flex-col space-y-4 text-heading">
                        <ul>
                          <li className="whitespace-nowrap pb-1.5 font-semibold">
                            <a href={WEB_ROUTES.HOME} className="nav-link-item">
                              Home
                            </a>
                          </li>
                          <li>
                            <a
                              className="font-semibold nav-link-item drop-trigger"
                              href={WEB_ROUTES.ABOUT}
                            >
                              About
                              <img
                                className="dropdown-icon"
                                src="/images/icon-black-cheveron-right.svg"
                                alt="cheveron-right"
                                width="16"
                                height="16"
                              />
                            </a>
                            <menu className="space-y-4 py-4 px-6">
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.ABOUT}>About us</a>
                              </li>
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.TEAM}>Leadership team</a>
                              </li>
                            </menu>
                          </li>
                          <li>
                            <a
                              href={WEB_ROUTES.SERVICES}
                              className="font-semibold nav-link-item drop-trigger"
                            >
                              Services
                              <img
                                className="dropdown-icon"
                                src="/images/icon-black-cheveron-right.svg"
                                alt="cheveron-right"
                                width="16"
                                height="16"
                              />
                            </a>
                            <menu className="space-y-4 py-4 px-6">
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.SERVICES_TURNKEY}>
                                  Turnkey Services
                                </a>
                              </li>
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.SERVICES_HEALTH_FACILITY}>
                                  Health Facility Planning
                                </a>
                              </li>
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.SERVICES_HEALTH_TECH}>
                                  Health Technology Planning and Management
                                </a>
                              </li>
                              <li className="sub-menu--item">
                                <a href={WEB_ROUTES.SERVICES_COMMISSIONING}>
                                  Commissioning
                                </a>
                              </li>
                              <li className="sub-menu--item">
                                <a
                                  href={WEB_ROUTES.SERVICES_PROJECT_MANAGEMENT}
                                >
                                  Programme and Project Management
                                </a>
                              </li>
                              <li className="sub-menu--item">
                                <a
                                  href={
                                    WEB_ROUTES.SERVICES_FACILITIES_MANAGEMENT
                                  }
                                >
                                  Facilities Maintenance Management
                                </a>
                              </li>
                            </menu>
                          </li>
                          <li className="whitespace-nowrap pb-1.5 font-semibold">
                            <a
                              href={WEB_ROUTES.PROJECTS}
                              className="nav-link-item"
                            >
                              Projects
                            </a>
                          </li>
                          <li className="whitespace-nowrap pb-1.5 font-semibold">
                            <a href={WEB_ROUTES.NEWS} className="nav-link-item">
                              News
                            </a>
                          </li>
                          <li className="whitespace-nowrap pb-1.5 font-semibold">
                            <a
                              href={WEB_ROUTES.CAREERS}
                              className="nav-link-item"
                            >
                              Careers
                            </a>
                          </li>
                          <li className="whitespace-nowrap pb-1.5 font-semibold">
                            <NavLink
                              to={`#`}
                              onClick={() => setOpen(true)}
                              className="nav-link-item peer-checked:translate-x-0"
                            >
                              Contact
                            </NavLink>
                          </li>
                        </ul>
                      </menu>

                      <div className="group md:mt-0 mt-8 md:hidden block">
                        <a
                          href="https://www.linkedin.com/company/sakhiwo-health-solutions"
                          target="_blank"
                          rel="noreferrer"
                          className="md:text-neutral-400 group-hover:text-red-600 md:float-end"
                        >
                          <h3 className="font-semibold float-left mr-2">
                            Follow us on LinkedIn
                          </h3>

                          <div className="text-red-600">
                            <svg
                              className="h-6 w-6 float-left"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                            </svg>
                          </div>

                          <div className="clear-both"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10 pl-0">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen md:max-w-3xl max-w-full transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white md:py-16 py-10 shadow-xl">
                  <div className="md:px-16 px-8">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="md:text-5xl text-3xl font-semibold text-gray-900">
                        Get in touch with us
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 md:px-16 px-8">
                    <div className="w-20 border-b-4 border-red-600 mb-5"></div>
                    <div className="isolate bg-white mt-10">
                      <FormContact />
                    </div>

                    <div className="mt-10">
                      <nav className="flex space-x-4" aria-label="Tabs">
                        {page?.acf?.offices?.map((tab, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              setActiveTab(i);
                            }}
                            className={`px-5 py-2 font-medium cursor-pointer ${
                              activeTab === i
                                ? "bg-red-600 text-white"
                                : "border-red-600 hover:bg-red-600 hover:text-white border-b-2"
                            }`}
                            aria-current={activeTab === i ? "page" : undefined}
                          >
                            {tab.office_name}
                          </div>
                        ))}
                      </nav>

                      {page?.acf?.offices?.map(
                        (tab, i) =>
                          activeTab === i && (
                            <div
                              key={i}
                              className="grid md:grid-cols-2 grid-cols-1"
                            >
                              <div className="py-5">
                                {tab?.office_address && (
                                  <>
                                    <h3 className="text-xl font-semibold mt-4">
                                      {tab?.office_name} Office
                                    </h3>
                                    <div className="mb-4">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: tab?.office_address
                                            .replace(/\n\n\n\n/g, "<br />")
                                            .replace(/\n\n\n/g, "<br />")
                                            .replace(/\n\n/g, "<br />")
                                            .replace(/\n/g, "<br />"),
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                                <h3 className="text-xl font-semibold">
                                  Contact Details
                                </h3>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: tab?.office_contacts
                                      .replace(/\n\n\n\n/g, "<br />")
                                      .replace(/\n\n\n/g, "<br />")
                                      .replace(/\n\n/g, "<br />")
                                      .replace(/\n/g, "<br />"),
                                  }}
                                />

                                {tab?.office_map_url && (
                                  <a
                                    href={tab?.office_map_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-red-600 inline-block mt-5"
                                  >
                                    <span className="float-left">
                                      View on Google maps
                                    </span>
                                    <i className="fi fi-rr-arrow-right float-left text-xl mt-0.5 ml-1.5"></i>
                                  </a>
                                )}
                              </div>

                              <div
                                className="py-5 md:py-8"
                                dangerouslySetInnerHTML={{
                                  __html: tab?.office_map,
                                }}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </header>
  );
}
