import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";

export default function ServicesSingle() {
  const [service, setService] = useState({});
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  const singleService = useMatch(WEB_ROUTES.SERVICES + "/:service");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [serviceMedia, setServiceMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "services"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get service
        axios
          .get(
            CONTENT_ROUTES.SERVICES("?slug=" + singleService?.params?.service)
          )
          .then((res) => {
            let serviceResponse = res.data?.length ? res.data[0] : {};
            setService(serviceResponse);

            //Get service media
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_PAGE(serviceResponse?.id))
              .then((res) => {
                setServiceMedia(res.data);
              });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleService]);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8md:px-0 px-8"></div>

        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 w-full">
              <h1 className="pb-5 md:text-5xl text-3xl font-semibold">
                {service?.title?.rendered}
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container mx-auto">
            <img
              className="md:h-[550px] h-[350px] w-full object-cover"
              src={
                serviceMedia?.find(
                  (media) => media?.id === service?.featured_media
                )?.source_url
              }
              alt={service?.title?.rendered}
            />
          </div>
        </section>

        <section className="bg-white md:py-16 py-10">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-5 md:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <h2
                    className="md:text-5xl text-3xl font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: service?.content?.rendered
                        ?.replace(/<p.*?>*?<\/p>/g, "")
                        ?.replace(/<h2.*?>/g, ""),
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: service?.content?.rendered
                          ?.replace(/<h2.*?>*?<\/h2>/g, "")
                          ?.replace(/<p.*?>/g, ""),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {service?.acf?.service_expertise_fields?.length !== 0 && (
          <section className="relative md:mb-16 mb-0">
            <div className="absolute w-1/2 h-full bg-gray-950 right-0 top-0 -z-10"></div>
            <div className="container mx-auto bg-gray-950 md:py-16 py-10 md:px-16 px-8">
              <h2 className="md:text-4xl text-3xl text-white mb-4 font-semibold">
                {service?.acf?.service_expertise_title}
              </h2>

              <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                {service?.acf?.service_expertise_fields?.map((item, i) => (
                  <div
                    key={i}
                    className={`relative md:pl-10 md:pr-10 ${
                      item?.service_offering_benefits_field_description
                        ? "py-10"
                        : "py-2"
                    }`}
                  >
                    <dt className="text-xl font-semibold text-white">
                      <div className="pl-0 text-red-600 text-2xl float-left">
                        <i className="fi fi-rr-bullet"></i>
                      </div>
                      <div className="md:float-left">
                        {item?.service_expertise_field_title}
                      </div>
                      <div className="clear-both"></div>
                    </dt>
                    <dd className="mt-2 text-base text-neutral-400 ml-6">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.service_expertise_field_description,
                        }}
                      />
                      <div className="clear-both"></div>
                    </dd>
                  </div>
                ))}
              </dl>

              {service?.acf?.service_expertise_additional_comments && (
                <div
                  className="container mx-auto text-white mt-4"
                  dangerouslySetInnerHTML={{
                    __html: service?.acf?.service_expertise_additional_comments,
                  }}
                />
              )}
            </div>
          </section>
        )}

        {service?.acf?.service_offering_benefits_fields?.length !== 0 && (
          <section className="bg-gray-200 md:py-16 py-10">
            <div className="mx-auto container md:px-0 px-8">
              <h2 className="md:py-5 text-4xl font-semibold">
                {service?.acf?.service_offering_benefits_title}
              </h2>

              <div className="md:px-16">
                <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                  {service?.acf?.service_offering_benefits_fields?.map(
                    (item, i) => (
                      <div
                        key={i}
                        className={`relative md:pl-10 md:pr-10 ${
                          item?.service_offering_benefits_field_description
                            ? "pt-10"
                            : "pt-2"
                        }`}
                      >
                        <dt className="text-xl font-semibold text-gray-900 flex">
                          <div className="pl-0 text-red-600 text-2xl ">
                            <i className="fi fi-rr-bullet"></i>
                          </div>
                          <div>
                            {item?.service_offering_benefits_field_title}
                          </div>
                        </dt>
                        <dd className="mt-2 text-base text-gray-600 ml-6">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.service_offering_benefits_field_description,
                            }}
                          />
                        </dd>
                      </div>
                    )
                  )}
                </dl>
              </div>
            </div>
          </section>
        )}

        <section className="relative isolate overflow-hidden">
          <img
            src={
              pageMedia?.find(
                (media) => media?.id === page?.acf?.services_footer_image
              )?.source_url
            }
            alt={page?.acf?.services_footer_title}
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="h-full w-full bg-gray-900/50 md:py-20 py-10 md:px-36 px-8">
            <div className="container mx-auto">
              <h2 className="md:text-4xl text-3xl text-white mb-4 font-semibold">
                {page?.acf?.services_footer_title}
              </h2>

              <div className="mx-auto grid max-w-2xl grid-cols-1 md:gap-24 gap-0 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {page?.acf?.services_footer_fields?.map((item, i) => (
                  <div key={i} className="flex gap-x-4 md:py-10 py-5">
                    <div
                      className="font-semibold text-white md:text-5xl text-3xl"
                      dangerouslySetInnerHTML={{
                        __html: item?.services_footer_field_icon,
                      }}
                    />

                    <div className="text-base text-white">
                      <h3 className="text-xl font-semibold mb-3">
                        {item?.services_footer_field_title}
                      </h3>
                      <p className="text-gray-300">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item?.services_footer_field_description,
                          }}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <NavLink
                  to={page?.acf?.services_footer_cta?.url}
                  onClick={() => {
                    (page?.acf?.services_footer_cta?.url === "#." ||
                      page?.acf?.services_footer_cta?.url === "#") &&
                      setOpenCloseContact(true);
                  }}
                  className="bg-red-600 px-5 py-2 md:mt-10 my-4 text-white hover:bg-gray-950 mx-auto inline-block"
                >
                  {page?.acf?.services_footer_cta?.title}{" "}
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
